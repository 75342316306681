@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

.newbuttonClass {
  background-image: none!important;
  background-color: #4773b3!important;
}

.btn-primary:hover {
  color: #4773b3 !important;
  background-color: white!important;
  border-color: #4773b3!important;
}

.btn-primary {
  background-color: #4773b3!important;
  color: white!important;
  border-color: #4773b3 !important;
}

.nb-theme-default nb-tabset .tab.active .tab-link {
  color: #4773b3!important;
}

.nb-theme-default nb-tabset .tab.active .tab-link::before {
  color: #4773b3!important;
  background-color: #4773b3 !important;
}

.nb-theme-default nb-tabset .tab-link:hover {
  color: #4773b3 !important;
}

.nb-theme-default .header-container nb-action nb-icon {
  color: white!important;
}

.nb-theme-default nb-action nb-icon {
  color: #ffffff !important;
}

.nb-theme-default nb-menu .menu-item a.active {
  background-color: #d3d3d35e!important;
}

.nb-theme-default nb-checkbox.status-basic .custom-checkbox.checked {
  background-color: #4773b3 !important;
  border-color: #4773b3 !important;
}

nb-icon.menu-icon.ng-tns-c128-1.ng-star-inserted:hover {
  color: #4773b3 !important;
}

.nb-theme-default nb-menu .menu-item a:hover {
  color: #4773b3 !important;
}

.nb-theme-default nb-sidebar.compacted nb-menu .menu-item a.active::before {
  background: #4773b3;
}

.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a.active,.nb-theme-default nb-menu .menu-item a {
  font-size: 11px!important
}

.available-items {
  height: 200px!important;
  overflow-y: scroll!important;
}

span.selected-item {
  max-width: none!important;
  margin: 2px 0!important;
}

.admin-login-btn {
  width: 40%;
}

.adminForgot-pass-btn {
  float: right;
}

.nb-theme-default ngx-login nb-layout .layout .layout-container .content .columns nb-layout-column {
  background-color: #4773b3!important;
}

.nb-theme-default nb-layout-header.fixed~.layout-container nb-sidebar .main-container {
  height: calc(100vh - 4.75rem)
}

.nb-theme-default nb-layout.with-subheader nb-sidebar .main-container {
  box-shadow: none
}

.nb-theme-default nb-sidebar {
  background-color: #fff;
  box-shadow: 0 .5rem 1rem 0 rgba(44,51,73,.1);
  color: #222b45;
  font-family: Open Sans,sans-serif;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.25rem;
  max-width: 13rem;
}

.nb-theme-default nb-sidebar .main-container {
width: 13rem!important;
}

.nb-theme-default nb-menu .menu-item a.active {
  background-color: #d3d3d35e!important;
}

.nb-theme-default nb-menu .menu-item a.active {
  background-color: hsla(0,0%,82.7%,.3686274509803922)!important;
}

.nb-theme-default nb-menu .menu-item > .menu-items {
  padding: 0px!important;
}

.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a.active,.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a.active:hover,.nb-theme-default nb-menu .menu-item>.menu-items>.menu-item a:hover,.nb-theme-default nb-menu .menu-item a.active,.nb-theme-default nb-menu .menu-item a:hover {
  border-bottom: 1px solid #d3d3d3!important
}

.nb-theme-default nb-sidebar .main-container {
  height: 100vh;
  width: 16rem
}

@media only screen and (min-width: 1366px) {
  .externalSitesLogin {
    left: -15%!important;
    margin-top: 10%!important;
  }
  .forgotPassBtn {
    left: 43%!important;
  }
  .cdk-overlay-pane nb-card-body {
    // max-height: 420px!important;
    overflow-y: scroll!important;
  }
  .captcha-container{
    margin-left: -17px!important;
  }
  iframe[title="reCAPTCHA"] {
    transform: rotateY(38deg)!important;
    // transform-origin: 0 0!important;
  }
  .forgotPassBtn {
    top: 21px!important;
  }
}

@media only screen and (max-width: 1366px) {
  iframe[title="reCAPTCHA"] {
    transform: rotateY(38deg)!important;
    transform-origin: 0 0!important;
  }
  .forgotPassBtn {
    top: 21px!important;
  }
}

@media only screen and (min-width: 1440px) {
  .externalSitesLogin {
    margin-top: 6%!important;
  }
  .forgotPassBtn {
    left: 45% !important;
    margin-top: 27px;
  }
}

@media only screen and (min-width: 1680px) {
  .externalSitesLogin {
    margin-top: 5% !important;
    left: -16%!important;
  }
  .systemLogin {
    left: 15%!important;
  }
  .forgotPassBtn {
    left: 60%!important;
  }
}

@media only screen and (max-width: 1280px) {
  .captcha-container{
    margin-left: -17px!important;
  }
  iframe[title="reCAPTCHA"] {
    transform: rotateY(38deg)!important;
    transform-origin: 0 0!important;
  }
  .forgotPassBtn {
    top: 21px!important;
  }
}

@media only screen and (max-width: 1024px) {
  iframe[title="reCAPTCHA"] {
    transform: rotateY(45deg)!important;
    transform-origin: 0 0!important;
  }
  .forgotPassBtn {
    top: 21px!important;
  }
  .registerButton {
    width: 50%!important;
  }
  .captcha-container{
    margin-left: -17px!important;
  }
  .admin-login-btn {
    width: 100%!important;
  }
  .adminForgot-pass-btn {
    float: unset!important;
    margin: 1px 20px!important;
  }
}

// large screens
@media only screen and (max-width: 1920px) {
  .nb-theme-default nb-layout.window-mode .scrollable-container {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode .layout nb-layout-header {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode nb-layout-header.fixed {
    top: 0px!important;
    background-color: #4773B3!important;
    color: white!important;
  }
  .nb-theme-default nb-layout-header.fixed~.layout-container {
    padding-top: 4.75rem!important;
    min-height: 100vh
  }

  .nb-theme-default nb-layout-header.fixed~.layout-container nb-sidebar .main-container {
    height: calc(100vh - 4.75rem)
  }

  .nb-theme-default nb-layout.window-mode .layout nb-layout-header nav {
    max-width: none!important;
  }
  .cdk-overlay-pane nb-card-body {
    // max-height: 420px!important;
    overflow-y: scroll!important;
  }
  .model-full-width .model-full {
    height: 80%!important;
  }
}

@media only screen and (min-width: 1940px) {
  .nb-theme-default nb-layout.window-mode .scrollable-container {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode .layout nb-layout-header {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode nb-layout-header.fixed {
    top: 0px!important;
    background-color: #4773B3!important;
    color: white!important;
  }
  .nb-theme-default nb-layout-header.fixed~.layout-container {
    padding-top: 4.75rem!important;
    min-height: 100vh
  }

  .nb-theme-default nb-layout-header.fixed~.layout-container nb-sidebar .main-container {
    height: calc(100vh - 4.75rem)
  }
  .nb-theme-default nb-layout.window-mode .layout nb-layout-header nav {
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode nb-layout-header.fixed ~ .layout-container nb-sidebar .main-container-fixed {
    top: 75px!important;
  }
  .nb-dialog-container{
    margin-top: -173%!important;
  }
  .listTableBody {
    max-height: auto!important;
  }
}

@media only screen and (min-width:2219px) {
  .nb-theme-default nb-layout-header.fixed~.layout-container {
    padding-top:0rem!important;
    min-height: 100vh
  }
}
 
@media only screen and (min-width: 2560px) {
  .nb-theme-default nb-layout.window-mode .scrollable-container {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode .layout nb-layout-header {
    width: 100%!important;
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode nb-layout-header.fixed {
    top: 0px!important;
    background-color: #4773B3!important;
    color: white!important;
  }
  .nb-theme-default nb-layout-header.fixed~.layout-container {
    padding-top: 0rem!important;
  }
  .nb-theme-default nb-layout.window-mode .layout nb-layout-header nav {
    max-width: none!important;
  }
  .nb-theme-default nb-layout.window-mode nb-layout-header.fixed ~ .layout-container nb-sidebar .main-container-fixed {
    top: 75px!important;
  }
  .nb-dialog-container{
    margin-top: -173%!important;
  }
  .model-full-width .model-full {
    height: 40%!important;
  }
  .listTableBody {
    max-height: auto!important;
  }
}

@media only screen and (max-width: 2000) {
  .cdk-overlay-pane.model-full-width {
    width: auto!important;
    max-width: none!important;
    min-width: unset!important;
    height: 90% !important;
  }
  .listTableBody {
    max-height: auto!important;
  }
}

.cdk-overlay-pane.model-medium {
  width: 90%!important;
  max-width: 90%!important;
  min-width: 90%!important;
}

// .cdk-overlay-pane.model-full-width {
//   width: 95%;
//   max-width: 95%;
//   min-width: 95%;
//   height: 90%!important;
// }

.model-full-width .model-full {
  min-width: 100%!important;
  width: 100%!important;
  max-width: 100%!important;
  height: 99%;
}

.model-full-width nb-dialog-container {
  width: 100%!important;
}

body.nb-theme-default {
  overflow-y: scroll!important;
}

.listTableBody {
  max-height: 350px;
}